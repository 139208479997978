<div id="testimonials" class="testimonial-section mt-5 mb-5">
    <div class="container">
        <div class="section-title">
            <span>متحدون برؤية:</span>
            <h2 class="foire">اكتشف فريق ProBoutik</h2>
        </div>
        <div class="row align-items-center  mb-5">

            <div class=" mb-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/ceo.png" alt="client image">
                                <div class="client-info">
                                    <h3>Thierno Sakho</h3>
                                    <span>المؤسس المشارك، الرئيس التنفيذي

                                    </span>
                                </div>
                                <p>يتمتع بخبرة تزيد عن 15 عامًا في مجال الخدمات المصرفية الاستثمارية والتمويل الأصغر وصناعة رأس المال الاستثماري
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/cto.png" alt="client image">
                                <div class="client-info">
                                    <h3>Abdoulaye FAYE
                                    </h3>
                                    <span>المؤسس المشارك CTO

                                    </span>
                                </div>
                                <p>يتمتع بخبرة تزيد عن 7 سنوات في مجال تكنولوجيا المعلومات وإدارة المنتجات في الشركات الناشئة في مجال التكنولوجيا المالية وSaas.</p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>

                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="section-title">
                <h1 class="foire">انضم إلى فريقنا المتحمس:</h1>
                <p class="rej-text">استكشف الفرص المتاحة لنا وجلب خبرتك لتشكيل المستقبل.</p>
                <div class="navbar-btn ">
                    <a routerLink="/ ">انضم إلينا</a>
                </div>
            </div>
            <h1 class="foire mb-3">أسئلة إضافية</h1>
            <div class="accordion mb-5" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            1. ما هو تطبيق بروبوتيك ؟
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            تطبيق ProBoutik هو تطبيق جوال ثوري مصمم للتجار الصغار وتجار التجزئة. فهو يقدم حلاً كاملاً لرقمنة المعاملات وإدارة النقد وتذكيرات الدفع وغير ذلك الكثير. </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            2.كيف تعمل رقمنة المعاملات؟
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            تعد تحويل المعاملات الرقمية باستخدام ProBoutikApp أمرًا بسيطًا. يسجل التطبيق جميع معاملات الدفع المؤجلة الخاصة بك، مما يضمن إمكانية التتبع الشفاف وإدارة أفضل لعمليات عملك. </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. كيف يمكنني الاستفادة من التمويل عبر تطبيق ProBoutik؟
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            يقدم تطبيق ProBoutik التمويل على أساس الاعتمادات الممنوحة لعملائك في شكل العوملة الصغيرة. يتيح لك ذلك تجديد تدفقك النقدي ومواصلة نموك، مع تجنب الضغوط المالية. </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. كيف تعمل تذكيرات الدفع؟
                    </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            تعمل تذكيرات الدفع التلقائي لتطبيق ProBoutik على تبسيط عملية التحصيل. يمكنك إرسال تذكيرات إلى عملائك باستخدام رابط الدفع، مما يوفر تجربة سلسة وشفافة للجميع. </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            5. ما هي قطاعات النشاط التي تستفيد من تطبيق ProBoutik؟
                    </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            يفيد ProBoutikApp مجموعة متنوعة من القطاعات، من البيع بالتجزئة إلى الأغذية والخدمات.
                        </div>
                    </div>
                </div>
                <div class="section-title mt-5 mb-5">
                    <h3 class="foire">هل لديك أسئله أخرى ؟</h3>
                    <p class="rej-text">لمعرفة المزيد، لا تتردد في الاتصال بنا.</p>
                    <div class="navbar-btn ">
                        <a routerLink="/ ">انضم إلينا</a>
                    </div>
                </div>


                <div class="section-title mt-5">
                    <h1 class="rej">تسهيل إدارتك في متناول يدك مع ProboutikApp.</h1>
                    <p class="rej-text">خذ زمام المبادرة الآن وقم بتنزيل تطبيق ProBoutik لإدارة الأعمال الأمثل.</p>
                    <div class="navbar-btn-t ">
                        <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">تحميل مجاني</a>
                        <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">تحميل مجاني</a>

                    </div>
                </div>



                <div class="contact mt-5">
                    <form action="" class="form">
                        <div class="form-group mb-3">
                            <label for="nom">اسم</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">بريد</label>
                            <input type="email" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">رسالة</label>
                            <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="اكتب رسالة تصويتك"></textarea>
                        </div>
                        <div class="navbar-btn-t offset-4">
                            <a>مبعوث</a>
                        </div>
                    </form>
                </div>
            </div>

        </div>


    </div>

</div>