import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SassLandingOneComponent } from './components/pages/sass-landing-one/sass-landing-one.component';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';

import { NavbarComponent } from './components/common/navbar/navbar.component';

import { FooterComponent } from './components/common/footer/footer.component';
import { NewsletterComponent } from './components/common/newsletter/newsletter.component';

import { AppBannerComponent } from './components/pages/app-landing/app-banner/app-banner.component';
import { AppWhyChooseComponent } from './components/pages/app-landing/app-why-choose/app-why-choose.component';
import { AppFeaturesComponent } from './components/pages/app-landing/app-features/app-features.component';
import { AppScreenshotsComponent } from './components/pages/app-landing/app-screenshots/app-screenshots.component';
import { AppHowToUseComponent } from './components/pages/app-landing/app-how-to-use/app-how-to-use.component';
import { AppPricingComponent } from './components/pages/app-landing/app-pricing/app-pricing.component';
import { AppTestimonialsComponent } from './components/pages/app-landing/app-testimonials/app-testimonials.component';

import { ModalComponent } from './components/common/modal/modal.component';
import { AppLandingEnComponent } from './components/pages/app-landing-en/app-landing-en.component';
import { AppBannerEnComponent } from './components/pages/app-landing-en/app-banner/app-banner-en.component';
import { AppFeaturesEnComponent } from './components/pages/app-landing-en/app-features/app-features.component-en';
import { AppWhyChooseEnComponent } from './components/pages/app-landing-en/app-why-choose/app-why-choose.component-en';
import { AppPricingEnComponent } from './components/pages/app-landing-en/app-pricing/app-pricing.component-en';
import { AppTestimonialsEnComponent } from './components/pages/app-landing-en/app-testimonials/app-testimonials.component-en';
import { AppLandingArComponent } from './components/pages/app-landing-ar/app-landing-ar.component';
import { AppBannerArComponent } from './components/pages/app-landing-ar/app-banner/app-banner-ar.component';
import { AppWhyChooseArComponent } from './components/pages/app-landing-ar/app-why-choose/app-why-choose.component-ar';
import { AppFeaturesArComponent } from './components/pages/app-landing-ar/app-features/app-features.component-ar';
import { AppPricingArComponent } from './components/pages/app-landing-ar/app-pricing/app-pricing.component-ar';
import { AppTestimonialsArComponent } from './components/pages/app-landing-ar/app-testimonials/app-testimonials.component-ar';
import { NavbarEnComponent } from './components/common/navbaren/navbar.component-en';
import { NavbarArComponent } from './components/common/navbarar/navbar.component-ar';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PolitiqueComponent } from './politique/politique.component';

@NgModule({
    declarations: [
        AppComponent,
        SassLandingOneComponent,
        AppLandingComponent,
        NavbarComponent,

        FooterComponent,
        NewsletterComponent,

        AppBannerComponent,
        AppBannerArComponent,
        AppBannerEnComponent,
        AppWhyChooseComponent,
        AppWhyChooseEnComponent,
        AppWhyChooseArComponent,
        AppFeaturesComponent,
        AppFeaturesEnComponent,
        AppFeaturesArComponent,
        AppScreenshotsComponent,
        AppHowToUseComponent,
        AppPricingComponent,
        AppPricingEnComponent,
        AppPricingArComponent,
        AppTestimonialsComponent,
        AppTestimonialsEnComponent,
        AppTestimonialsArComponent,

        ModalComponent,
        AppLandingEnComponent,
        AppLandingArComponent,
        NavbarEnComponent,
        NavbarArComponent,
        PolitiqueComponent,
        
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxScrollTopModule,

    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
