import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/common/modal/modal.component';

@Component({
  selector: 'app-app-banner-en',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerEnComponent {


  constructor(private modalService: NgbModal, private platform:Platform) {}

    ngOnInit(): void {
    }

    isAndroid(): boolean {
      return this.platform.ANDROID;
    }

    isIOS(): boolean {
      return this.platform.IOS;
    }

openModal(){
    const modalREf = this.modalService.open(ModalComponent, { centered: true, size: 'xl',  });


}


detectDevice(): void {
    const userAgent = navigator.userAgent;

if (/android/i.test(userAgent)) {
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/windows/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  }

  console.log('====================================');

console.log('====================================');

  }

}
