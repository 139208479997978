import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-app-testimonials',
    templateUrl: './app-testimonials.component.html',
    styleUrls: ['./app-testimonials.component.scss']
})
export class AppTestimonialsComponent implements OnInit {

    constructor(private platform:Platform) { }

    ngOnInit(): void {
    }

    deviceInfo: string = '';

  detectDevice(): void {
    const userAgent = navigator.userAgent;

if (/android/i.test(userAgent)) {
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
    this.deviceInfo = "Vous utilisez un iPhone ou un autre appareil iOS.";
  } else if (/windows/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  }

  console.log('====================================');
  console.log(this.deviceInfo);

console.log('====================================');

  }
    testimonialSlides: OwlOptions = {
        loop: true,
        margin: 30,
        dots: true,
        center: true,
        smartSpeed: 2000,
        navSpeed:700,
        dotsSpeed:2000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            768: {
                items: 2
            },
            1000: {
                items: 1
            }
        }
       

}
isAndroid(): boolean {
  return this.platform.ANDROID;
}

isIOS(): boolean {
  return this.platform.IOS;
}
}
