import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-pricing',
    templateUrl: './app-pricing.component.html',
    styleUrls: ['./app-pricing.component.scss']
})
export class AppPricingComponent implements OnInit {

    constructor(private platform:Platform) { }

    ngOnInit(): void {}

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }
    deviceInfo: string = '';

    detectDevice(): void {
      const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
    } else if (/windows/i.test(userAgent)) {
      this.deviceInfo = "Vous utilisez un appareil Windows.";
    } else if (/macintosh|mac os x/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
    } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
    }

    console.log('====================================');
    console.log(this.deviceInfo);

  console.log('====================================');

    }
    
    isAndroid(): boolean {
        return this.platform.ANDROID;
      }
    
      isIOS(): boolean {
        return this.platform.IOS;
      }
}
