import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-pricing-en',
    templateUrl: './app-pricing.component.html',
    styleUrls: ['./app-pricing.component.scss']
})
export class AppPricingEnComponent implements OnInit {

    constructor(private platform:Platform) { }

    ngOnInit(): void {}

	isIOS(): boolean {
		return this.platform.IOS;
	}
	isAndroid(): boolean {
		return this.platform.ANDROID;
	}

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}
