<div id="about" class="why-choose pt-100">
    <div class="container">

        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Inscription Simple, Sans Contraintes :</h3>
                            Un premier pas sans complications. Rejoignez ProBoutikApp avec votre numéro de téléphone, évitant les formalités complexes. L'inscription devient aisée, ouvrant la voie à une expérience fluide dès le départ.
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Connexion01.png" alt="why choose image">
                </div>
            </div>
        </div>

        <div class="row align-items-center why reverse" data-aos="fade-right" data-aos-duration="3000">
            <div class="col-lg-4">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body offset-1">
                            <h3 class="mt-0 ">Suivi en Temps Réel :</h3>
                            Toujours dans le savoir. Accédez à un suivi en temps réel, visualisant l'état de chaque transaction instantanément. Soyez au courant, restez en contrôle, et créez des relations de confiance solides.
                        </div>
                    </div>


                </div>
            </div>

        </div>


        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Rappels de Paiement Automatiques :</h3>
                            Oubliez les soucis de recouvrement. Envoyez des rappels de paiement automatisés, offrant une expérience sans faille à vous et vos clients. Simplifiez le processus, renforcez votre réputation.
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Envoyer01.png" alt="why choose image">
                </div>
            </div>
        </div>



        <div class="row align-items-center why reverse">
            <div class="col-lg-4" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Stimulez Votre Croissance avec un Financement Intégré :</h3>
                            Ne laissez pas la trésorerie vous freiner. Bénéficiez du financement stratégique de ProBoutik App pour reconstituer vos ressources financières. Développez-vous sans entraves, réalisez vos ambitions avec confiance.
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>