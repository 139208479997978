import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SassLandingOneComponent } from './components/pages/sass-landing-one/sass-landing-one.component';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';

import { AppLandingEnComponent } from './components/pages/app-landing-en/app-landing-en.component';
import { AppLandingArComponent } from './components/pages/app-landing-ar/app-landing-ar.component';
import { PolitiqueComponent } from './politique/politique.component';

const routes: Routes = [
    {path: '', component: AppLandingComponent},
    {path: 'entreprise', component: SassLandingOneComponent},
    {path: 'fr', component: AppLandingComponent},
    {path: 'en', component: AppLandingEnComponent},
    {path: 'ar', component: AppLandingArComponent},
    { path: 'politique-confidentialite', component: PolitiqueComponent },

    {path: '**', component: AppLandingComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
