<div id="pricing" class="pricing-section">
    <div class="container">
        <div class="section-title">
            <h2>اعتماد الأداء بسعر منخفض:</h2>
            <p>تطبيق ProBoutik يدعمك في تنمية أعمالك مقابل 990 فرنك شهريًا فقط. اختر اشتراكًا شهريًا ميسور التكلفة. ابدأ بشهر مجاني ثم قم بالترقية إلى اشتراك بقيمة 990 فرنك شهريًا أو 10000 فرنك سنويًا.
            </p>
        </div>
        <div class="pricing-tabs">

            <div class="tabs-container" data-aos="fade-left">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-up" data-aos-duration="3000">
                            <div class="price-card">
                                <p>شهريا</p>
                                <h3>990 FCFA<span> /شهر</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>الاعتراف الائتماني</li>
                                        <li><i class="flaticon-checked"></i> إدارة محفظة العملاء</li>
                                        <li><i class="flaticon-checked"></i> المحاسبة البسيطة </li>
                                        <li><i class="flaticon-checked"></i> طلب التمويل </li>
                                        <li><i class="flaticon-checked"></i> مراقبة الذمم المدينة والدائنة في الوقت الحقيقي</li>
                                        <li><i class="flaticon-checked"></i>تذكير الدفع الآلي عبر الرسائل القصيرة</li>
                                        <li><i class="flaticon-checked"></i> تقارير وكشوفات الحساب</li>
                                        <li><i class="flaticon-checked"></i> تطبيق متعدد اللغات</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">تحميل مجاني</a>
                                    <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">تحميل مجاني</a>
    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-down" data-aos-duration="3000">
                            <div class="price-card">
                                <p>Annuel</p>
                                <h3>10 000 FCFA<span> /سنة</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>الاعتراف الائتماني</li>
                                        <li><i class="flaticon-checked"></i> إدارة محفظة العملاء</li>
                                        <li><i class="flaticon-checked"></i> المحاسبة البسيطة </li>
                                        <li><i class="flaticon-checked"></i> طلب التمويل </li>
                                        <li><i class="flaticon-checked"></i> مراقبة الذمم المدينة والدائنة في الوقت الحقيقي</li>
                                        <li><i class="flaticon-checked"></i>تذكير الدفع الآلي عبر الرسائل القصيرة</li>
                                        <li><i class="flaticon-checked"></i> تقارير وكشوفات الحساب</li>
                                        <li><i class="flaticon-checked"></i> تطبيق متعدد اللغات</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">تحميل مجاني</a>
                                    <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">تحميل مجاني</a>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <p>Personal Pack</p>
                                <h3>$30<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Rapports et relevés de compte</li>
                                        <li><i class="flaticon-checked"></i> Application Multilingue</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card active-price business-class ">
                                <p>Business Class</p>
                                <h3>$60<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <p>Premium</p>
                                <h3>$90<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-checked"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>