import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor() { }



  deviceInfo: string = '';

  detectDevice(): void {
    const userAgent = navigator.userAgent;

if (/android/i.test(userAgent)) {
    this.deviceInfo = "Vous utilisez un appareil Android.";
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    this.deviceInfo = "Vous utilisez un iPhone ou un autre appareil iOS.";
  } else if (/windows/i.test(userAgent)) {
    this.deviceInfo = "Vous utilisez un appareil Windows.";
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    this.deviceInfo = "Vous utilisez un MacBook ou un autre appareil macOS.";
  } else {
    this.deviceInfo = "Vous utilisez un autre type d'appareil.";
  }
}

}
