<div id="home" class="main-banner banner-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container container1">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-sm-4">
                        <div class="banner-text">
                            <h2 class="animate__animated  animate__backInLeft">Unlock Your Business Success with <br>ProBoutik App</h2>
                            <p class="animate__animated  animate__backInLeft">Discover how ProBoutik App modernizes your invoices, strengthens your cash flow through financing and creates lasting relationships with your customers for long-term growth. Take your business to the to the next level.</p>
                            <div class="banner-btn animate__animated  animate__backInLeft">
                                <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Download for Free</a>
                                <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Download for Free</a>


                            </div>

                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-4">
                        <div class="banner-img text-end animated animate__heartBeat" data-aos="zoom-out" data-aos-delay="300">
                            <img src="assets/img/app-landing/welcome.png" alt="iphone">

                        </div>
                    </div>
                </div>
            </div>



            <div class="container container2">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="banner-text2">
                            <h1>Unlock Your Business Success with <br>ProBoutik App</h1>
                            <p>Discover how ProBoutik App modernizes your invoices, strengthens your cash flow through financing and creates lasting relationships with your customers for long-term growth. Take your business to the to the next level.</p>
                            <div class="banner-btn2">
                                <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Download for Free</a>
                                <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Download for Free</a>

                                
                                <!-- <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="banner-img2 text-end">
                            <img src="assets/img/app-landing/welcome.png" alt="iphone">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>



</div>
<div class="container">
    <div class="paragraph">
        <p class="par"> ProBoutik App introduces a new era of simplicity and efficiency. Digitize your transactions, take advantage of financing to replenish your cash flow and avoid financial stress. Explore a future where business management becomes a driving force
            for growth.</p>
    </div>



    <div class="video">
        <div class="play">

            <svg (click)="openModal()" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="118" height="125" viewBox="0 0 118 125" fill="none">
                <path d="M109.051 48.4337C120.217 54.4869 120.217 70.5131 109.051 76.5663L23.6249 122.873C12.9639 128.652 1.70446e-06 120.933 1.55985e-06 108.807L4.55452e-07 16.1934C3.10845e-07 4.06696 12.964 -3.6518 23.6249 2.12715L109.051 48.4337Z" fill="white"/>
                </svg>
        </div>
    </div>

</div>
