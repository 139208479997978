<div id="about" class="why-choose pt-100">
    <div class="container">

        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Simple registration, without constraints</h3>
                            A first step without complications. Join ProBoutikApp with your phone number, avoiding complex formalities. Registration becomes easy, paving the way for a seamless experience right from the start.
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Connexion01.png" alt="why choose image">
                </div>
            </div>
        </div>
        <div class="row align-items-center why reverse" data-aos="fade-right" data-aos-duration="3000">
            <div class="col-lg-4">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body offset-1">
                            <h3 class="mt-0 ">Real-time Tracking :</h3>
                            Always in the know. Access real-time monitoring, viewing the status of each transaction instantly. Be in the know, stay in control, and build strong, trusting relationships
                        </div>
                    </div>


                </div>
            </div>

        </div>


        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Automatic Payment Reminders :</h3>
                            Forget collection worries. Send automated payment reminders, providing a seamless experience for you and your customers. Simplify the process, boost your reputation.
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Envoyer01.png" alt="why choose image">
                </div>
            </div>
        </div>



        <div class="row align-items-center why reverse">
            <div class="col-lg-4" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">Drive Your Growth with Integrated Financing :</h3>
                            Don't let cash hold you back. Benefit from ProBoutik App's strategic financing to replenish your financial resources. Develop yourself without hindrance, realize your ambitions with confidence.
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>