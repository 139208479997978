<div id="services" class="feature-section pt-100 pb-70 mb-5">
    <div class="container">
        <div class="section-title" data-wow-duration="1s">
            <h2>Their Word, Our Pride :</h2>
            <p>Testimonials from ProboutikApp users</p>
        </div>
        <owl-carousel-o [options]="screenshotSlider">
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"Thanks to ProBoutik App, I now control the amounts owed by my clients. Valuable peace of mind reigns in my management. Each transaction is transparent, offering unrivaled efficiency."</span>
                    <div class="div">
                        <span class="name">Moussa Ndiaye</span> <br>
                        <span class="prof">fabric seller</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"ProBoutik App has given me invaluable peace of mind. I now easily grant credit, knowing that each transaction is closely monitored. My credit sales have increased, boosting my turnover."</span>
                    <div class="div">
                        <span class="name">Awa Gueye</span> <br>
                        <span class="prof">Cosmetic product seller</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"ProBoutik App has given me invaluable peace of mind. I now easily grant credit, knowing that each transaction is closely monitored. My credit sales have increased, boosting my turnover."</span>
                    <div class="div">
                        <span class="name">Momodou Diallo</span> <br>
                        <span class="prof">Shopkeeper in Médina</span>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>

    </div>


</div>

