<div id="pricing" class="pricing-section">
    <div class="container">
        <div class="section-title">
            <h2>Adopt Performance at a Low Price :</h2>
            <p>ProBoutik App supports you in growing your business for only $1.65 per month. Opt for an affordable monthly subscription. Start with a free month and then upgrade to a subscription of $1.65 per month or $16.50 per year.
            </p>
        </div>
        <div class="pricing-tabs">

            <div class="tabs-container" data-aos="fade-left">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-up" data-aos-duration="3000">
                            <div class="price-card">
                                <p>Monthly</p>
                                <h3>$1.65<span> /Month</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>Credit recognition</li>
                                        <li><i class="flaticon-checked"></i> Customer portfolio management</li>
                                        <li><i class="flaticon-checked"></i> Mini accounting </li>
                                        <li><i class="flaticon-checked"></i> Request for financing </li>
                                        <li><i class="flaticon-checked"></i> Real-time monitoring of receivables and payables</li>
                                        <li><i class="flaticon-checked"></i>Automated payment reminders via SMS</li>
                                        <li><i class="flaticon-checked"></i> Account reports and statements</li>
                                        <li><i class="flaticon-checked"></i> Multilingual Application</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                                                    <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Download for Free</a>

                                    <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Download for Free</a>
    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-down" data-aos-duration="3000">
                            <div class="price-card">
                                <p>Yearly</p>
                                <h3>$16.50<span> /Year</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>Credit recognition</li>
                                        <li><i class="flaticon-checked"></i> Customer portfolio management</li>
                                        <li><i class="flaticon-checked"></i> Mini accounting </li>
                                        <li><i class="flaticon-checked"></i> Request for financing </li>
                                        <li><i class="flaticon-checked"></i> Real-time monitoring of receivables and payables</li>
                                        <li><i class="flaticon-checked"></i>Automated payment reminders via SMS</li>
                                        <li><i class="flaticon-checked"></i> Account reports and statements</li>
                                        <li><i class="flaticon-checked"></i> Multilingual Application</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                                                    <a  *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Download for Free</a>

                                    <a  *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Download for Free</a>
    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <p>Personal Pack</p>
                                <h3>$30<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Rapports et relevés de compte</li>
                                        <li><i class="flaticon-checked"></i> Application Multilingue</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card active-price business-class ">
                                <p>Business Class</p>
                                <h3>$60<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <p>Premium</p>
                                <h3>$90<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-checked"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">Subscribe</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>