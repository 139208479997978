<div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismissAll('Close click')"></button>
        <div class="modal-body">
            <div class="body">
                <iframe #videoIframe class="embed-responsive-item" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src="assets/video/pub.mp4"></iframe>
            </div>
        </div>

    </div>
</div>