import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-app-features-en',
  templateUrl: './app-features.component.html',
  styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesEnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  screenshotSlider: OwlOptions = {
    loop: true,
    margin: 30,
    dots: true,
    center: true,
    smartSpeed: 2000,
    navSpeed:700,
    dotsSpeed:2000,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        768: {
            items: 2
        },
        1000: {
            items: 5
        }
    }
}

}
