<!-- privacy-policy.component.html -->

<div style="max-width: 800px; margin: 0 auto; padding: 20px;">
    <h1 style="font-size: 28px; color: #333;">Politique de Confidentialité de Proboutik.io</h1>
    <p style="font-size: 16px; color: #666;">Dernière mise à jour : 10 Mai 2024</p>
    <p style="font-size: 16px; color: #666;">Chez Proboutik.io, nous nous engageons à protéger votre vie privée. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons, et sauvegardons vos informations lorsque vous visitez notre site web www.proboutik.io (« le Site »). Nous vous invitons à lire cette politique de confidentialité attentivement afin de comprendre nos pratiques concernant vos données personnelles.</p>
    <h2 style="font-size: 20px; color: #333;">1. Collecte des informations</h2>
    <p style="font-size: 16px; color: #666;">Informations que vous nous fournissez :</p>
    <ul style="font-size: 16px; color: #666; margin-left: 20px;">
        <li>Informations d'inscription, telles que votre nom, adresse email, adresse postale, numéro de téléphone, et mot de passe lorsque vous créez un compte.</li>
        <li>Détails de transaction, y compris les informations sur les produits que vous achetez, méthodes de paiement et informations de livraison.</li>
    </ul>
    <p style="font-size: 23px; color: #666;">Informations collectées automatiquement :</p>
    <ul style="font-size: 16px; color: #666; margin-left: 20px;">
        <li>Données de journal et d'utilisation, qui peuvent inclure des informations comme votre adresse IP, type de navigateur, pages consultées, et les dates et heures d'accès.</li>
        <li>Cookies et technologies similaires pour suivre votre navigation et vos préférences sur le Site.</li>
    </ul>
    <h2 style="font-size: 23px; color: #333;">2. Utilisation des informations</h2>
    <p style="font-size: 16px; color: #666;">Nous utilisons vos informations pour :</p>
    <ul style="font-size: 16px; color: #666; margin-left: 20px;">
        <li>Fournir, exploiter et maintenir notre Site.</li>
        <li>Améliorer, personnaliser et élargir notre Site.</li>
        <li>Comprendre et analyser votre utilisation de notre Site.</li>
        <li>Développer de nouveaux produits, services, fonctionnalités et fonctionnalités.</li>
        <li>Communiquer avec vous, soit directement, soit par l'un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d'autres informations relatives au site, et à des fins de marketing et de promotion.</li>
    </ul>
    <h2 style="font-size: 23px; color: #333;">3. Partage des informations</h2>
    <p style="font-size: 16px; color: #666;">Vos informations peuvent être partagées avec des tiers dans les cas suivants :</p>
    <ul style="font-size: 16px; color: #666; margin-left: 20px;">
        <li>Fournisseurs de services tiers qui effectuent des services pour nous, tels que le traitement des paiements, l'analyse de données, et l'envoi d'emails.</li>
        <li>Si requis par la loi, comme pour se conformer à une demande légale ou pour protéger nos droits.</li>
    </ul>
    <h2 style="font-size: 23px; color: #333;">4. Sécurité des données</h2>
    <p style="font-size: 16px; color: #666;">Nous prenons des mesures raisonnables pour protéger vos informations contre l'accès non autorisé, la perte, le vol, l'usage abusif ou la modification.</p>
    <h2 style="font-size: 20px; color: #333;">5. Vos droits</h2>
    <p style="font-size: 16px; color: #666;">Vous avez le droit de demander l'accès, la correction ou la suppression de vos données personnelles que nous détenons. Vous pouvez également vous opposer à certaines façons dont nous utilisons vos informations ou retirer votre consentement à tout moment.</p>
    <h2 style="font-size: 20px; color: #333;">6. Modifications de cette politique</h2>
    <p style="font-size: 16px; color: #666;">Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. La version la plus récente de la politique sera toujours publiée sur notre Site.</p>
    <h2 style="font-size: 20px; color: #333;">7. Contactez-nous</h2>
    <p style="font-size: 16px; color: #666;">Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à <a href="mailto:fintech@proxalys.io">fintech@proxalys.io</a></p>
</div>
