import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-pricing-ar',
    templateUrl: './app-pricing.component.html',
    styleUrls: ['./app-pricing.component.scss']
})
export class AppPricingArComponent implements OnInit {

    constructor(private platform:Platform) { }

    ngOnInit(): void {}

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    isAndroid(): boolean {
        return this.platform.ANDROID;
      }
  
  
    
      isIOS(): boolean {
        return this.platform.IOS;
      }
  
}
