<app-navbar-en></app-navbar-en>
<app-app-banner-en>

</app-app-banner-en>

<app-app-why-choose-en></app-app-why-choose-en>

<app-app-features-en></app-app-features-en>

<app-app-pricing-en></app-app-pricing-en>
<!--
<app-app-screenshots></app-app-screenshots>

<app-app-how-to-use></app-app-how-to-use>-->


<app-app-testimonials-en></app-app-testimonials-en>