<div id="about" class="why-choose pt-100">
    <div class="container">

        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">تسجيل بسيط، دون قيود
                            </h3>
                            خطوة أولى بدون تعقيدات. انضم إلى ProBoutikApp باستخدام رقم هاتفك، وتجنب الإجراءات الشكلية المعقدة. أصبح التسجيل سهلاً، مما يمهد الطريق لتجربة سلسة منذ البداية.
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Connexion01.png" alt="why choose image">
                </div>
            </div>
        </div>
        <div class="row align-items-center why reverse" data-aos="fade-right" data-aos-duration="3000">
            <div class="col-lg-4">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body offset-1">
                            <h3 class="mt-0 ">تتبع في الوقت الحقيقي:</h3>
                            دائما في المعرفة. الوصول إلى المراقبة في الوقت الحقيقي، وعرض حالة كل معاملة على الفور. كن على اطلاع، وحافظ على زمام الأمور، وقم ببناء علاقات قوية مبنية على الثقة </div>
                    </div>


                </div>
            </div>

        </div>


        <div class="row align-items-center mb-5 why ">

            <div class="col" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">تذكير الدفع التلقائي:</h3>
                            ننسى المخاوف جمع. أرسل تذكيرات تلقائية بالدفع، مما يوفر تجربة سلسة لك ولعملائك. تبسيط العملية وتعزيز سمعتك. </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Envoyer01.png" alt="why choose image">
                </div>
            </div>
        </div>



        <div class="row align-items-center why reverse">
            <div class="col-lg-4" data-aos="fade-right" data-aos-duration="3000">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/Home01.png" alt="why choose image">
                </div>
            </div>
            <div class="col" data-aos="fade-left" data-aos-duration="3000">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">


                    <div class="media d-flex">
                        <div class="media-body">
                            <h3 class="mt-0">قم بقيادة نموك من خلال التمويل المتكامل :</h3>
                            لا تدع النقد يعيقك. استفد من التمويل الاستراتيجي لتطبيق ProBoutik لتجديد مواردك المالية. طور نفسك دون عائق، حقق طموحاتك بثقة. </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>