import { Component } from '@angular/core';

@Component({
  selector: 'app-politique',
  templateUrl: './politique.component.html',
  styleUrls: ['./politique.component.scss']
})
export class PolitiqueComponent {

}
