import { Component, OnInit } from '@angular/core';
import * as AOS from "aos";



@Component({
  selector: 'app-app-why-choose-ar',
  templateUrl: './app-why-choose.component.html',
  styleUrls: ['./app-why-choose.component.scss']
})
export class AppWhyChooseArComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();

  }

}
