<app-navbar></app-navbar>

<app-app-banner>

</app-app-banner>

<app-app-why-choose></app-app-why-choose>

<app-app-features></app-app-features>

<app-app-pricing></app-app-pricing>
<!--
<app-app-screenshots></app-app-screenshots>

<app-app-how-to-use></app-app-how-to-use>-->


<app-app-testimonials></app-app-testimonials>