<div id="services" class="feature-section pt-100 pb-70 mb-5">
    <div class="container">
        <div class="section-title" data-wow-duration="1s">
            <h2>Leur Parole, Notre Fierté :</h2>
            <p>Témoignages des utilisateurs de ProboutikApp</p>
        </div>
        <owl-carousel-o [options]="screenshotSlider">
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"Grâce à ProBoutik App, je maîtrise désormais les montants dus par mes clients. Une tranquillité d'esprit précieuse règne dans ma gestion. Chaque transaction est transparente, offrant une efficacité sans égale."</span>
                    <div class="div">
                        <span class="name">Moussa Ndiaye</span> <br>
                        <span class="prof">Vendeur de tissus</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"Depuis que je profite de ProBoutik App, les inquiétudes liées aux paiements de mes clientes ont disparu. Ma relation avec elles s'est transformée, tout est clair et fluide. Je peux me concentrer sur l'essentiel."</span>
                    <div class="div">
                        <span class="name">Awa Gueye</span> <br>
                        <span class="prof">Vendeuse de produit cosmétics</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"ProBoutik App m'a apporté une tranquillité d'esprit inestimable. J'accorde maintenant aisément du crédit, sachant que chaque transaction est suivie de près. Mes ventes à crédit ont augmenté, stimulant ainsi mon chiffre d'affaires."</span>
                    <div class="div">
                        <span class="name">Momodou Diallo</span> <br>
                        <span class="prof">Boutiquier à Médina</span>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>

    </div>


</div>