<div class="container">
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-sm-6">
                    <div class=" pl-90 logo">
                        <img src="assets/img/app-landing/Lobo_Proboutik.png " alt="logo " [width]="90 ">
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="footer-widget pl-90">
                        <h3>Contact</h3>
                        <ul>
                            <li><a href="#"><i class="flaticon-right"></i>  thierno.sakho@proxalys.io</a></li>
                            <li><a href="#"><i class="flaticon-right"></i> +221 77 253 15 09</a></li>
                            <li><a href="#"><i class="flaticon-right"></i> Dakar Sénégal</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="footer-widget pl-90">
                        <h3>Télécharger l'application</h3>
                        <img src="../../../../assets/img/app-landing/store.png" alt="">
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="footer-widget pl-90">
                        <h3>Réseaux Sociaux</h3>
                        <ul>
                            <li><a href="https://www.facebook.com/ProxalysSn" target="_blank"><i class="flaticon-right"></i> Facebook</a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=%2B221760145096&data=ARDrn1NV9GMq4v2Ux_uUwALvi6vmy_CxyXmmKa9LjHe9hjcG4KcEUVoWMFp5V_wEIB1XCOdPNcnDztxxY9JMQQyvwFXmXZt1W87FYWwJaRkqXE-7UHyDJvVglD7teBeQ7WNgoC52kvLf4pdqyW_Qr_KlXA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR2BnA3SQzE8ubaVF0En_Ydc7XK8g1u8mVuqNb71H2ule1L6AaY6X_5K4dQ"
                                    target="_blank"><i class="flaticon-right"></i> WhatsApp</a></li>
                            <li><a href="https://www.linkedin.com/company/proboutik/ " target="_blank"><i class="flaticon-right"></i> Linkedin</a></li>

                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <h1 class="lh-1 float-start mt-5 mb-5">© 2023 Proboutik. All rights reserved.</h1>
                
            </div>
            <a class="lh-1 float-end mt-5 mb-5 pointer" routerLink="/politique-confidentialite">Politique de Confidentialité</a>
        </div>
    </footer>
</div>