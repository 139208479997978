import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-app-testimonials-en',
    templateUrl: './app-testimonials.component.html',
    styleUrls: ['./app-testimonials.component.scss']
})
export class AppTestimonialsEnComponent implements OnInit {

    constructor(private platform:Platform) { }

    ngOnInit(): void {
    }
    isAndroid(): boolean {
        return this.platform.ANDROID;
      }
  
      isIOS(): boolean {
        return this.platform.IOS;
      }

    testimonialSlides: OwlOptions = {
        loop: true,
        margin: 30,
        dots: true,
        center: true,
        smartSpeed: 2000,
        navSpeed:700,
        dotsSpeed:2000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2
            },
            1000: {
                items: 1
            }
        }

}}
