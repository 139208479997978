<div id="testimonials" class="testimonial-section mt-5 mb-5">
    <div class="container">
        <div class="section-title">
            <span>United by a Vision :</span>
            <h2 class="foire">Discover the ProBoutik's team</h2>
        </div>
        <div class="row align-items-center  mb-5">

            <div class=" mb-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/ceo.png" alt="client image">
                                <div class="client-info">
                                    <h3>Thierno Sakho</h3>
                                    <span>Co-founder ,CEO

                                    </span>
                                </div>
                                <p>With over 15 years in the investment banking, microfinance, VC industry

                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/cto.png" alt="client image">
                                <div class="client-info">
                                    <h3>Abdoulaye FAYE
                                    </h3>
                                    <span>Co-founder CTO

                                    </span>
                                </div>
                                <p>With over 7 years of IT and Product Management on Fintech and Saas startups. </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>

                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="section-title">
                <h1 class="foire">Join our passionate team :</h1>
                <p class="rej-text">Explore our opportunities and bring your expertise to shape the future.</p>
                <div class="navbar-btn ">
                    <a routerLink="/ ">Join us</a>
                </div>
            </div>
            <h1 class="foire mb-3">Frequently Asked Questions</h1>
            <div class="accordion mb-5" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            1. What is ProBoutik App ?
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutik App is a revolutionary mobile application designed for micro-merchants and retailers. It offers a complete solution for the digitalization of transactions, cash management, payment reminders and much more.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            2. How does the digitalization of transactions work ?
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Digitizing transactions with ProBoutikApp is simple. The application records all your deferred payment transactions, ensuring transparent traceability and better management of your business operations.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. How can I benefit from financing via ProBoutik App?
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutik App offers financing based on credits granted to your customers in the form of micro-factoring. This allows you to replenish your cash flow and continue your growth, while avoiding financial stress.
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. How do payment reminders work ?
                    </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutik App's automatic payment reminders simplify collection. You can send reminders to your customers with a payment link, providing a smooth and transparent experience for everyone. </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            5. Which sectors of activity benefit from ProBoutik App ?
                    </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutikApp benefits a variety of sectors, from retail to food and services.
                        </div>
                    </div>
                </div>
                <div class="section-title mt-5 mb-5">
                    <h3 class="foire">do you have any other questions ?</h3>
                    <p class="rej-text">To learn more, do not hesitate to contact us.</p>
                    <div class="navbar-btn ">
                        <a routerLink="/ ">Join us</a>
                    </div>
                </div>


                <div class="section-title mt-5">
                    <h1 class="rej">Facilitate Your Management at Your Fingertips with ProboutikApp.</h1>
                    <p class="rej-text">Take the initiative now and download ProBoutik App for optimized business management.</p>
                    <div class="navbar-btn-t ">
                        

                        
                    </div>
                </div>



                <div class="contact mt-5">
                    <form action="" class="form">
                        <div class="form-group mb-3">
                            <label for="nom">Name</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">Mail</label>
                            <input type="email" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">Message</label>
                            <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Message"></textarea>
                        </div>
                        <div class="navbar-btn-t offset-4">
                            <a>Send</a>
                        </div>
                    </form>
                </div>
            </div>

        </div>


    </div>

</div>
