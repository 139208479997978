<div class="container">
    <div id="pricing" class="pricing-section">
        <div class="section-title">
            <h2>Adoptez la Performance à Petit Prix :</h2>
            <p>ProBoutik App vous accompagne dans la croissance de votre entreprise pour seulement 990fr par mois. Optez pour un abonnement mensuel abordable. Commencez avec un mois gratuit et passez ensuite à l'abonnement à 990fr par mois ou à 10000fr par
                an.
            </p>
        </div>
        <div class="pricing-tabs">

            <div class="tabs-container" data-aos="fade-left">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-up" data-aos-duration="3000">
                            <div class="price-card">
                                <p>Mensuel</p>
                                <h3>990 FCFA<span> /Mois</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>Constatation de crédits</li>
                                        <li><i class="flaticon-checked"></i> Gestion portefeuille clients</li>
                                        <li><i class="flaticon-checked"></i> Mini comptabilité </li>
                                        <li><i class="flaticon-checked"></i> Demande de financement </li>
                                        <li><i class="flaticon-checked"></i> Suivi en temps réels des créances et des dettes</li>
                                        <li><i class="flaticon-checked"></i>Rappels automatisés de paiements via sms</li>
                                        <li><i class="flaticon-checked"></i> Rapports et relevés de compte</li>
                                        <li><i class="flaticon-checked"></i> Application Multilingue</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" data-aos="zoom-in-down" data-aos-duration="3000">
                            <div class="price-card">
                                <p>Annuel</p>
                                <h3>10 000 FCFA<span> /An</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>Constatation de crédits</li>
                                        <li><i class="flaticon-checked"></i> Gestion portefeuille clients</li>
                                        <li><i class="flaticon-checked"></i> Mini comptabilité </li>
                                        <li><i class="flaticon-checked"></i> Demande de financement </li>
                                        <li><i class="flaticon-checked"></i> Suivi en temps réels des créances et des dettes</li>
                                        <li><i class="flaticon-checked"></i>Rappels automatisés de paiements via sms</li>
                                        <li><i class="flaticon-checked"></i> Rapports et relevés de compte</li>
                                        <li><i class="flaticon-checked"></i> Application Multilingue</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>
                                    

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <p>Personal Pack</p>
                                <h3>$30<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Rapports et relevés de compte</li>
                                        <li><i class="flaticon-checked"></i> Application Multilingue</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">S'abonner</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card active-price business-class ">
                                <p>Business Class</p>
                                <h3>$60<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">S'abonner</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <p>Premium</p>
                                <h3>$90<span> /Annual</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-checked"></i> Money Return</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">S'abonner</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>