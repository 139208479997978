<div id="home" class="main-banner banner-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container container1">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-sm-4">
                        <div class="banner-text">
                            <h2 class="animate__animated  animate__backInLeft">Débloquez Votre Succès Commercial avec<br> ProBoutik App</h2>
                            <p class="animate__animated  animate__backInLeft">Découvrez comment ProBoutik App modernise vos factures, renforce votre trésorerie à travers du financement et crée des relations durables avec vos clients pour une croissance à long terme. Faites passer votre entreprise au
                                niveau supérieur.</p>
                            <div class="banner-btn animate__animated  animate__backInLeft">
                                <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                                                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>


                            

                            </div>

                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-4">
                        <div class="banner-img text-end animated animate__heartBeat" data-aos="zoom-out" data-aos-delay="300">
                            <img src="assets/img/app-landing/welcome.png" alt="iphone">

                        </div>
                    </div>
                </div>
            </div>

            <div class="container container2">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="banner-text2">
                            <h1>Débloquez Votre Succès Commercial avec<br> ProBoutik App</h1>
                            <p>Découvrez comment ProBoutik App modernise vos factures, renforce votre trésorerie à travers du financement et crée des relations durables avec vos clients pour une croissance à long terme. Faites passer votre entreprise au
                                niveau supérieur.</p>
                            <div class="banner-btn2">
                                <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                                                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>


                                <!-- <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="banner-img2 text-end">
                            <img src="assets/img/app-landing/welcome.png" alt="iphone">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>



</div>
<div class="container">
    <div class="paragraph">
        <p class="par"> ProBoutik App introduit une nouvelle ère de simplicité et d'efficacité. Digitalisez vos transactions, profitez du financement pour reconstituer votre trésorerie et éviter les tensions financières. Explorez un avenir où la gestion commerciale devient
            une force motrice de croissance.</p>
    </div>



    <div class="video">
        <div class="play">

            <svg (click)="openModal()" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="118" height="125" viewBox="0 0 118 125" fill="none">
                <path d="M109.051 48.4337C120.217 54.4869 120.217 70.5131 109.051 76.5663L23.6249 122.873C12.9639 128.652 1.70446e-06 120.933 1.55985e-06 108.807L4.55452e-07 16.1934C3.10845e-07 4.06696 12.964 -3.6518 23.6249 2.12715L109.051 48.4337Z" fill="white"/>
                </svg>
        </div>
    </div>

</div>
