<div id="testimonials" class="testimonial-section mt-5 mb-5">
    <div class="container">
        <div class="section-title">
            <span>Unis par une Vision :</span>
            <h2 class="foire">Découvrez l'Équipe ProBoutik</h2>
        </div>
        <div class="row mb-5">

            <div class="mb-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/ceo.png" alt="client image">
                                <div class="client-info">
                                    <h3>Thierno Sakho</h3>
                                    <span>Co-founder ,CEO

                                    </span>
                                </div>
                                <p>With over 15 years in the investment banking, microfinance, VC industry

                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/cto.png" alt="client image">
                                <div class="client-info">
                                    <h3>Abdoulaye FAYE
                                    </h3>
                                    <span>Co-founder CTO

                                    </span>
                                </div>
                                <p>With over 7 years of IT and Product Management on Fintech and Saas startups. </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>

                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="section-title">
                <h1 class="foire">Rejoignez Notre Équipe Passionnée :</h1>
                <p class="rej-text">Explorez nos opportunités et apportez votre expertise pour façonner l'avenir.</p>
                <div class="navbar-btn ">
                    <a routerLink="/ ">Rejoignez-nous</a>
                </div>
            </div>
            <h1 class="foire mb-3">Foire aux Questions</h1>
            <div class="accordion mb-5" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            1. Qu'est-ce que ProBoutik App ?
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutik App est une application mobile révolutionnaire conçue pour les micro-marchands et les retailers. Elle offre une solution complète pour la digitalisation des transactions, la gestion de la trésorerie, les rappels de paiements et bien plus encore.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            2. Comment fonctionne la digitalisation des transactions ?
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            La digitalisation des transactions avec ProBoutikApp est simple. L'application enregistre toutes vos transactions à paiement différé, garantissant une traçabilité transparente et une meilleure gestion de vos opérations commerciales.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. Comment puis-je bénéficier du financement via ProBoutik App ?
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutik App offre un financement basé sur les crédits octroyés à vos clients sous forme de micro-affacturage. Cela vous permet de reconstituer votre trésorerie et de poursuivre votre croissance, tout en évitant les tensions financières.
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. Comment les rappels de paiements fonctionnent-ils ?
                    </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Les rappels de paiements automatiques de ProBoutik App simplifient le recouvrement. Vous pouvez envoyer des rappels à vos clients avec un lien de paiement, offrant une expérience fluide et transparente pour tous.
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            5. Quels secteurs d'activité bénéficient de ProBoutik App ?
                    </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            ProBoutikApp profite à une variété de secteurs, de la vente au détail à l'alimentation en passant par les services.
                        </div>
                    </div>
                </div>
                <div class="section-title mt-5 mb-5">
                    <h3 class="foire">Vous avez d'autres questions ?</h3>
                    <p class="rej-text">N'hésitez pas à nous contacter pour obtenir des réponses détaillées.</p>
                    <div class="navbar-btn ">
                        <a routerLink="/ ">Rejoignez-nous</a>
                    </div>
                </div>


                <div class="section-title mt-5">
                    <h1 class="rej">Facilitez Votre Gestion au Bout de Vos Doigts avec ProboutikApp.</h1>
                    <p class="rej-text">Prenez l'initiative dès maintenant et téléchargez ProBoutik App pour une gestion commerciale optimisée.</p>
                    <div class="navbar-btn-t ">
                        <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>
                        

                    </div>
                </div>



                <div class="contact mt-5">
                    <form action="" class="form">
                        <div class="form-group mb-3">
                            <label for="nom">Nom</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">Mail</label>
                            <input type="email" class="form-control">
                        </div>
                        <div class="form-group mb-3">
                            <label for="nom">Message</label>
                            <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Ecrivez votre message"></textarea>
                        </div>
                        <div class="navbar-btn-t offset-4">
                            <a>Envoyer</a>
                        </div>
                    </form>
                </div>
            </div>

        </div>


    </div>

</div>