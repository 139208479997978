import { Component } from '@angular/core';

@Component({
  selector: 'app-app-landing-ar',
  templateUrl: './app-landing-ar.component.html',
  styleUrls: ['./app-landing-ar.component.scss']
})
export class AppLandingArComponent {

}
