<div id="services" class="feature-section pt-100 pb-70 mb-5">
    <div class="container">
        <div class="section-title" data-wow-duration="1s">
            <h2>كلمتهم، فخرنا:</h2>
            <p>شهادات من مستخدمي ProboutikApp</p>
        </div>
        <owl-carousel-o [options]="screenshotSlider">
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"بفضل تطبيق ProBoutik، أصبح بإمكاني الآن التحكم في المبالغ المستحقة على عملائي. وتسود إدارتي راحة البال القيمة. وتتمتع كل معاملة بالشفافية، وتوفر كفاءة لا مثيل لها."</span>
                    <div class="div">
                        <span class="name">Moussa Ndiaye</span> <br>
                        <span class="prof">بائع القماش</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"منذ أن استفدت من تطبيق ProBoutik، اختفت مخاوف عملائي بشأن المدفوعات. لقد تغيرت علاقتي معهم، كل شيء واضح ومرن. يمكنني التركيز على الأساسيات."</span>
                    <div class="div">
                        <span class="name">Awa Gueye</span> <br>
                        <span class="prof">بائع مستحضرات التجميل</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card-temoin" data-wow-duration="1s">
                    <span class="temoin">"لقد منحني تطبيق ProBoutik راحة بال لا تقدر بثمن. فأنا الآن أمنح الائتمان بسهولة، مع العلم أن كل معاملة تتم مراقبتها عن كثب. وقد زادت مبيعات الائتمان الخاصة بي، مما أدى إلى زيادة حجم مبيعاتي."</span>
                    <div class="div">
                        <span class="name">Momodou Diallo</span> <br>
                        <span class="prof">صاحب محل بالمدينة المنورة</span>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>

    </div>


</div>