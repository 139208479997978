import { Platform } from '@angular/cdk/platform';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services/services.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private router:Router, private service: ServicesService, private platform:Platform) {}

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }


    deviceInfo: string = '';

  detectDevice(): void {
    const userAgent = navigator.userAgent;

if (/android/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/windows/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.proxalys.merchant.mobile.app.merchantapp'
  }

  console.log('====================================');
  console.log(this.deviceInfo);

console.log('====================================');

  }


  selectLanguage(select:any){
    this.router.navigateByUrl(select.value)
console.log('====================================');
console.log(select.value);
console.log('====================================');
  }

  isAndroid(): boolean {
    return this.platform.ANDROID;
  }

  isIOS(): boolean {
    return this.platform.IOS;
  }
}
