import { Component } from '@angular/core';

@Component({
  selector: 'app-app-landing-en',
  templateUrl: './app-landing-en.component.html',
  styleUrls: ['./app-landing-en.component.scss']
})
export class AppLandingEnComponent {

}
