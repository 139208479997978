<div class="div">
    <nav class="navbar navbar-expand-lg navbar-light bg-light " [class.active]="classApplied " [ngClass]="{ 'sticky': isSticky} ">
        <div class="container ">
            <div>
                <a class="navbar-brand " routerLink="/app-landing">
                    <img src="assets/img/app-landing/Lobo_Proboutik.png " alt="logo " [width]="115 ">
                </a>
            </div>
            <button class="navbar-toggler" type="button " (click)="toggleClass() ">
            <span class="burger-menu ">
                <span class="top-bar "></span>
                <span class="middle-bar "></span>
                <span class="bottom-bar "></span>
            </span>
        </button>
            <div class="collapse navbar-collapse mb-3" id="navbarSupportedContent ">
                <ul class="navbar-nav ms-auto ">
                    <li class="nav-item "><span class="nav-link " (click)="onClick( 'home') ">Accueil</span></li>
                    <li class="nav-item "><span class="nav-link " (click)="onClick( 'services') ">Services</span></li>
                    <li class="nav-item "><span class="nav-link " (click)="onClick( 'about') ">A Propos</span></li>

                </ul>
                <ul class="navbar-nav ms-auto for-responsive ">
                    <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'home') ">Home</span></li>
                    <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'services') ">Services</span></li>
                    <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'about') ">About Us</span></li>

                </ul>
                <div class="navbar-btn ">
                    <a *ngIf="isAndroid()" href="https://play.google.com/store/apps/details?id=com.mng.merchant.pro.btk.proboutik_v2&pcampaignid=web_share">Télécharger gratuitement</a>
                    <a *ngIf="isIOS()" href="https://apps.apple.com/fr/app/proboutik/id6475403053">Télécharger gratuitement</a>

                </div>
                <div class="language-picker js-language-picker" data-trigger-class="li4-btn li4-btn--subtle js-tab-focus">
                    <form action="" class="language-picker__form">

                        <select name="language-picker-select" id="language-picker-select" #select (change)="selectLanguage(select)">
                        <option disabled selected> Lang</option>
                        <option value="en" >English </option>
                        <option value="/">Français</option>
                        <option value="ar">عربي</option>
                      </select>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</div>