<div class="tab ">
    <div class=" container">
        <div class="row">
            <div class="col-1" routerLink="/" routerLinkActive="active">ProBoutik</div>
            <div class="col-1" routerLink="/entreprise">Entreprise</div>
        </div>
    </div>
</div>


<router-outlet>

</router-outlet>
<app-footer></app-footer>
<ngx-scrolltop></ngx-scrolltop>